import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Contact } from "../../interfaces/Contact";
import { Country } from "../../interfaces/Country";
import ButtonLoader from "../../Common/Loader/ButtonLoader";
import * as contactServices from "../../services/contact.service";
import Select from "react-select";
import { toast } from "react-toastify";

const ContactForm: React.FC<any> = ({
  id,
  pageSubject,
}: {
  id: string;
  pageSubject: string;
}) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryList = data.map((country: any) => ({
          name: country.name.common,
          code: country.cca2,
          phoneCode:
            country.idd.root +
            (country.idd.suffixes ? country.idd.suffixes[0] : ""),
        }));
        setCountries(countryList);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const initialValues: Contact = {
    firstName: "",
    lastName: "",
    country: "",
    countryCode: "",
    phoneNumber: "",
    email: "",
    subject: pageSubject ?? "",
    message: "",
  };

  const FormSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, "First name must contain at least 3 letters")
      .required(t("required")),
    lastName: Yup.string()
      .min(3, "Last name must contain at least 3 letters")
      .required(t("required")),
    country: Yup.string().required(t("required")),
    phoneNumber: Yup.string()
      .matches(/^\+?\d+$/, "Phone number must contain only numbers")
      .min(9, "Phone number must contain at least 9 digits")
      .required(t("required")),
    email: Yup.string().email("Invalid email address").required(t("required")),
    subject: Yup.string()
      .min(3, "Subject must contain at least 3 letters")
      .required(t("required")),
    message: Yup.string()
      .min(15, "Message must contain at least 15 letters")
      .required(t("required")),
  });

  const handleForm = async (values: Contact) => {
    setSubmitting(true);
    contactServices
      .create(values)
      .then((response: any) => {
        if (response.status === 200) {
          setSubmitting(false);
          toast.success(
            "Thank you! Your form has been submitted successfully.",
            {
              position: "top-right",
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div id={id} className="w-full bg-sky">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12 cursor-default">
        <div className="bg-gradient-to-r from-primary to-dark bg-clip-text text-transparent text-3xl lg:text-4xl font-extrabold uppercase drop-shadow-lightBlue">
          Contact Us Form
        </div>
        <p className="text-justify text-dark/50 text-lg font-normal leading-6 my-4">
          Ready for better tech? Let's get started! Contact us by fill out the
          form.
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={(values: Contact, { resetForm }) => {
            values.phoneNumber = values.countryCode + values.phoneNumber;
            console.log("Full phone number:", values.phoneNumber);

            handleForm(values);

            resetForm({
              values: {
                firstName: "",
                lastName: "",
                country: "",
                countryCode: "",
                phoneNumber: "",
                email: "",
                subject: "",
                message: "",
              },
            });
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form className="bg-white rounded-t-xl rounded-bl-xl p-4 lg:p-8">
              <div className="flex flex-col lg:flex-row gap-6 mb-6">
                <div className="w-full lg:w-1/2 flex flex-col">
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    ${
                      touched.firstName && errors.firstName
                        ? "border border-danger"
                        : "border-none"
                    }`}
                    disabled={isSubmitting}
                  />
                  {touched.firstName && errors.firstName && (
                    <p className="text-danger text-sm mt-1">
                      {errors.firstName}
                    </p>
                  )}
                </div>

                <div className="w-full lg:w-1/2 flex flex-col">
                  <Field
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    ${
                      touched.lastName && errors.lastName
                        ? "border border-danger"
                        : "border-none"
                    }`}
                    disabled={isSubmitting}
                  />
                  {touched.lastName && errors.lastName && (
                    <p className="text-danger text-sm mt-1">
                      {errors.lastName}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex-row gap-6 mb-6">
                <div className="w-full">
                  <Field name="country">
                    {({ field }: { field: any }) => (
                      <Select
                        {...field}
                        isSearchable
                        value={
                          values.country
                            ? {
                                value: values.country,
                                label:
                                  countries.find(
                                    (c: any) => c.code === values.country
                                  )?.name || values.country,
                              }
                            : null
                        }
                        placeholder="Search and select a country"
                        options={countries.map((country) => ({
                          value: country.code,
                          label: country.name,
                        }))}
                        onChange={(selectedOption: any) => {
                          const selectedCountry = countries.find(
                            (country) => country.code === selectedOption.value
                          );

                          setFieldValue("country", selectedCountry?.name); // Set country value
                          setFieldValue(
                            "countryCode",
                            selectedCountry?.phoneCode || ""
                          ); // Update phone number field with phone code
                        }}
                        className={`rounded-t-xl rounded-bl-xl focus:outline-none
                      ${
                        touched.country && errors.country
                          ? "border border-danger"
                          : "border-none"
                      }`}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            width: "100%",
                            backgroundColor: "#EBF5FF",
                            borderRadius: "0.75rem 0.75rem 0 0.75rem",
                            fontSize: "1rem",
                            color: "#012448",
                            fontWeight: "400",
                            padding: "1.25rem 1rem",
                            border: "#000",
                            outline: "none",
                            boxShadow: "none",
                            zIndex: "auto",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "rgba(1, 36, 72, 0.5)",
                            fontWeight: "300",
                          }),

                          option: (baseStyle, state) => ({
                            ...baseStyle,
                            background: state.isSelected ? "#17B0CE" : "#FFF",
                            "&:hover": {
                              background: state.isSelected
                                ? "#17B0CE"
                                : "#DCEAF8",
                            },
                            textAlign: "left",
                          }),
                        }}
                      />
                    )}
                  </Field>

                  {touched.country && errors.country && (
                    <p className="text-danger text-sm mt-1">{errors.country}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-6 mb-6">
                <div className="w-full lg:w-1/2 flex flex-col">
                  <div className="flex">
                    <Field
                      type="text"
                      name="countryCode"
                      placeholder="Code"
                      className={`w-1/6 bg-sky rounded-tl-xl rounded-bl-xl border-r border-gray text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    `}
                      disabled
                    />
                    <Field
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      className={`w-full bg-sky rounded-tr-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    ${
                      touched.phoneNumber && errors.phoneNumber
                        ? "border border-danger"
                        : "border-none"
                    }`}
                      disabled={isSubmitting}
                    />
                  </div>
                  {touched.phoneNumber && errors.phoneNumber && (
                    <p className="text-danger text-sm mt-1">
                      {errors.phoneNumber}
                    </p>
                  )}
                </div>

                <div className="w-full lg:w-1/2 flex flex-col">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                    ${
                      touched.email && errors.email
                        ? "border border-danger"
                        : "border-none"
                    }`}
                    disabled={isSubmitting}
                  />
                  {touched.email && errors.email && (
                    <p className="text-danger text-sm mt-1">{errors.email}</p>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <Field
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                  ${
                    touched.subject && errors.subject
                      ? "border border-danger"
                      : "border-none"
                  }`}
                  disabled={isSubmitting}
                />
                {touched.subject && errors.subject && (
                  <p className="text-danger text-sm mt-1">{errors.subject}</p>
                )}
              </div>

              <div className="mb-6">
                <Field
                  as="textarea"
                  rows={2}
                  type="text"
                  name="message"
                  placeholder="Message"
                  className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                  ${
                    touched.message && errors.message
                      ? "border border-danger"
                      : "border-none"
                  }`}
                  disabled={isSubmitting}
                />
                {touched.message && errors.message && (
                  <p className="text-danger text-sm mt-1">{errors.message}</p>
                )}
              </div>

              <button
                type="submit"
                className="w-full items-center justify-center rounded-t-xl rounded-bl-xl border border-none bg-gradient-to-r from-dark to-secondary p-4 text-white transition hover:bg-opacity-90"
              >
                {isSubmitting ? (
                  <span className="flex justify-center gap-2">
                    {t("Sending...")} <ButtonLoader />
                  </span>
                ) : (
                  <span className="text-lightGray text-base font-bold uppercase">
                    {t("send")}
                  </span>
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default ContactForm;
