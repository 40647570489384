export const terms = [
  {
    title: "Eligibility & Approval",
    description:
      "Resellers must provide valid company details, including a trade license, VAT registration, and a valid business address. \n GBT reserves the right to approve or reject any reseller application at its sole discretion. \n Resellers must not engage in any fraudulent or misleading activities when registering or conducting business.",
  },
  {
    title: "Reseller Obligations",
    description:
      "Resellers shall market, promote, and sell GBT’s products in compliance with all applicable laws and regulations.\n Resellers must not misrepresent GBT’s products or alter their specifications, branding, or warranty terms. \n Any marketing material using GBT’s name, logo, or product images must receive prior written approval from GBT.",
  },
  {
    title: "Pricing & Payments",
    description:
      "GBT will provide resellers with a product price list, which may be updated periodically. \n Payments must be made according to the terms specified in the invoice. Late payments may result in order suspension. \n Resellers are responsible for setting their own resale prices, except where pricing policies are mandated.",
  },
  {
    title: "Orders & Delivery",
    description:
      "All orders must be placed in writing and confirmed by GBT. \n GBT will make reasonable efforts to fulfill orders promptly but is not liable for delays due to unforeseen circumstances. \n Resellers must verify the orders received and report any discrepancies within 48 hours of delivery.",
  },
  {
    title: "Warranty & Returns",
    description:
      "Products are covered under the manufacturer’s warranty. Resellers must direct end-customers to the respective warranty provider. \n Returns are subject to GBT’s return policy and require prior approval before processing. \n Any unauthorized returns or damage caused by improper handling will not be accepted.",
  },
  {
    title: "Confidentiality",
    description:
      "Resellers shall maintain confidentiality regarding pricing, business strategies, and other proprietary information shared by GBT. \n Unauthorized disclosure of confidential information may lead to termination of reseller status and legal action.",
  },
  {
    title: "Termination",
    description:
      "Either party may terminate this agreement with a 30-day written notice. \n  GBT reserves the right to terminate a reseller’s account immediately in cases of fraud, non-compliance, or unethical business practices. \n Upon termination, resellers must cease using GBT’s branding and intellectual property immediately.",
  },
  {
    title: "Limitation of Liability",
    description:
      "GBT shall not be liable for indirect, incidental, or consequential damage arising from the reseller’s business operations. \n GBT does not guarantee sales or profitability to the reseller and is not responsible for their financial performance.",
  },
  {
    title: "Amendments & Governing Law",
    description:
      "GBT reserves the right to amend these terms at any time, with updates posted on the website. Continued use of reselling privileges constitutes acceptance of the new terms. \n This agreement is governed by the laws of the United Arab Emirates, and any disputes shall be resolved in the courts of the United Arab Emirates.",
  },
];

export const conclusion = {
  description:
    "By clicking “Agree” and submitting your reseller registration, you acknowledge that you have read, understood, and agreed to abide by these terms and conditions. ",
};
