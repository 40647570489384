import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Reseller } from "../../interfaces/Reseller";
import { Country } from "../../interfaces/Country";
import ButtonLoader from "../../Common/Loader/ButtonLoader";
import * as resellerServices from "../../services/reseller.service";
import Select from "react-select";
import { toast } from "react-toastify";

const ResellerForm: React.FC<any> = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const tradeLicenseRef = useRef<HTMLInputElement | null>(null); // Create a ref for tradeLicense input
  const vatCertificateRef = useRef<HTMLInputElement | null>(null); // Create a ref for vatCertificate input
  const filesRef = useRef<HTMLInputElement | null>(null); // Create a ref for files input

  const { t } = useTranslation();

  const options = [
    { value: "snr", label: "SNR" },
    { value: "astra", label: "Astra" },
    { value: "qtech", label: "Qtech" },
    { value: "spider-Radio", label: "Spider Radio" },
  ];

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryList = data.map((country: any) => ({
          name: country.name.common,
          code: country.cca2,
          phoneCode:
            country.idd.root +
            (country.idd.suffixes ? country.idd.suffixes[0] : ""),
        }));
        setCountries(countryList);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const initialValues: Reseller = {
    companyName: "",
    companyWebsite: "",
    businessType: "",
    customBusinessType: "",
    businessAddress: "",
    country: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    annualTurnover: "",
    expectedTurnover: "",
    industryFocus: "",
    customIndustryFocus: "",
    preferredBrands: [],
    tradeLicense: "",
    vatCertificate: "",
    files: "",
  };

  const FormSchema = Yup.object().shape({
    companyName: Yup.string()
      .min(3, "Company name must contain at least 3 letters")
      .required(t("required")),
    companyWebsite: Yup.string(),
    businessType: Yup.string().required(t("required")),
    customBusinessType: Yup.string(),
    businessAddress: Yup.string(),
    country: Yup.string().required(t("required")),
    fullName: Yup.string()
      .min(3, "Full name must contain at least 3 letters")
      .required(t("required")),
    email: Yup.string().email("Invalid email address").required(t("required")),
    phoneNumber: Yup.string()
      .matches(/^\+?\d+$/, "Phone number must contain only numbers")
      .min(9, "Phone number must contain at least 9 digits")
      .required(t("required")),
    jobTitle: Yup.string(),
    annualTurnover: Yup.string().required(t("required")),
    expectedTurnover: Yup.string().required(t("required")),
    industryFocus: Yup.string().required(t("required")),
    customIndustryFocus: Yup.string(),
    preferredBrands: Yup.array().required(t("required")),
    tradeLicense: Yup.string().required(t("required")),
    vatCertificate: Yup.string().required(t("required")),
    files: Yup.string(),
  });

  const handleForm = async (values: Reseller) => {
    setSubmitting(true);
    resellerServices
      .create(values)
      .then((response: any) => {
        console.log("🚀 ~reseller Services  .then ~ response:", response);
        if (response.status === 200) {
          setSubmitting(false);
          toast.success(
            "Thank you for applying! Our team will review your request and get back to you shortly.",
            {
              position: "top-right",
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-full bg-sky">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12 cursor-default">
        <div className="bg-gradient-to-r from-primary to-dark bg-clip-text text-transparent text-3xl lg:text-4xl font-extrabold uppercase drop-shadow-lightBlue">
          Reseller Form
        </div>
        <p className="text-justify text-dark/50 text-lg font-normal leading-6 my-4">
          Ready for better tech? Let's get started! Contact us by fill out the
          form.
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={(values: Reseller, { resetForm }) => {
            handleForm(values);
            resetForm();

            // Manually reset the file input field
            if (tradeLicenseRef.current) {
              tradeLicenseRef.current.value = "";
            }

            // Manually reset the vatCertificate input field
            if (vatCertificateRef.current) {
              vatCertificateRef.current.value = "";
            }

            // Manually reset the files input field
            if (filesRef.current) {
              filesRef.current.value = "";
            }
          }}
        >
          {({ values, setFieldValue, errors, touched, setTouched }) => (
            <Form className="flex flex-col gap-6">
              <div className="bg-white rounded-t-xl rounded-bl-xl p-4 lg:p-6">
                <div className="text-secondary text-md lg:text-lg font-extrabold uppercase mb-2">
                  Company Information
                </div>
                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div className="w-full lg:w-1/2 flex flex-col">
                    <Field
                      type="text"
                      name="companyName"
                      placeholder="Company Name"
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    ${
                      touched.companyName && errors.companyName
                        ? "border border-danger"
                        : "border-none"
                    }`}
                      disabled={isSubmitting}
                    />
                    {touched.companyName && errors.companyName && (
                      <p className="text-danger text-sm mt-1">
                        {errors.companyName}
                      </p>
                    )}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-col">
                    <Field
                      type="text"
                      name="companyWebsite"
                      placeholder="Company Website"
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                    ${
                      touched.companyWebsite && errors.companyWebsite
                        ? "border border-danger"
                        : "border-none"
                    }`}
                      disabled={isSubmitting}
                    />
                    {touched.companyWebsite && errors.companyWebsite && (
                      <p className="text-danger text-sm mt-1">
                        {errors.companyWebsite}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div
                    className={`flex flex-col ${
                      values.businessType === "other" ? "lg:w-1/2" : "lg:w-full"
                    }`}
                  >
                    <Field
                      as="select"
                      name="businessType"
                      onChange={(e: any) => {
                        const value = e.target.value;
                        setFieldValue("businessType", value);
                      }}
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark font-normal px-4 py-5 capitalize focus:outline-none
                      ${
                        touched.businessType && errors.businessType
                          ? "border border-danger"
                          : "border-none"
                      }`}
                      disabled={isSubmitting}
                    >
                      <option value="">Select a Business Type</option>
                      <option value="distributor">Distributor</option>
                      <option value="reseller">Reseller</option>
                      <option value="systemIntegrator">
                        System Integrator
                      </option>
                      <option value="other">Other</option>
                    </Field>
                    {touched.businessType && errors.businessType && (
                      <p className="text-danger text-sm mt-1">
                        {errors.businessType}
                      </p>
                    )}
                  </div>

                  {values.businessType === "other" && (
                    <div className="w-full lg:w-1/2 flex flex-col">
                      <Field
                        type="text"
                        name="customBusinessType"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setFieldValue("customBusinessType", value);
                        }}
                        placeholder="Other Business Type"
                        className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                        ${
                          touched.customBusinessType &&
                          errors.customBusinessType
                            ? "border border-danger"
                            : "border-none"
                        }`}
                        disabled={isSubmitting}
                      />
                      {touched.customBusinessType &&
                        errors.customBusinessType && (
                          <p className="text-danger text-sm mt-1">
                            {errors.customBusinessType}
                          </p>
                        )}
                    </div>
                  )}
                </div>

                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div className="w-full">
                    <Field
                      type="text"
                      name="businessAddress"
                      placeholder="Business Address"
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                    ${
                      touched.businessAddress && errors.businessAddress
                        ? "border border-danger"
                        : "border-none"
                    }`}
                      disabled={isSubmitting}
                    />
                    {touched.businessAddress && errors.businessAddress && (
                      <p className="text-danger text-sm mt-1">
                        {errors.businessAddress}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex-row gap-6 mb-4">
                  <div className="w-full">
                    <Field name="country">
                      {({ field }: { field: any }) => (
                        <Select
                          {...field}
                          isSearchable
                          value={
                            values.country
                              ? {
                                  value: values.country,
                                  label:
                                    countries.find(
                                      (c: any) => c.code === values.country
                                    )?.name || values.country,
                                }
                              : null
                          }
                          placeholder="Search and select a country"
                          options={countries.map((country) => ({
                            value: country.code,
                            label: country.name,
                          }))}
                          onChange={(selectedOption: any) => {
                            const selectedCountry = countries.find(
                              (country) => country.code === selectedOption.value
                            );

                            setFieldValue("country", selectedCountry?.name); // Set country value
                          }}
                          className={`rounded-t-xl rounded-bl-xl focus:outline-none
                      ${
                        touched.country && errors.country
                          ? "border border-danger"
                          : "border-none"
                      }`}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "100%",
                              backgroundColor: "#EBF5FF",
                              borderRadius: "0.75rem 0.75rem 0 0.75rem",
                              fontSize: "1rem",
                              color: "#012448",
                              fontWeight: "400",
                              padding: "1.25rem 1rem",
                              border: "#000",
                              outline: "none",
                              boxShadow: "none",
                              zIndex: "auto",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "rgba(1, 36, 72, 0.5)",
                              fontWeight: "300",
                            }),

                            option: (baseStyle, state) => ({
                              ...baseStyle,
                              background: state.isSelected ? "#17B0CE" : "#FFF",
                              "&:hover": {
                                background: state.isSelected
                                  ? "#17B0CE"
                                  : "#DCEAF8",
                              },
                              textAlign: "left",
                            }),
                          }}
                        />
                      )}
                    </Field>

                    {touched.country && errors.country && (
                      <p className="text-danger text-sm mt-1">
                        {errors.country}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-t-xl rounded-bl-xl p-4 lg:p-8">
                <div className="text-secondary text-md lg:text-lg font-extrabold uppercase mb-2">
                  Contact Person Details
                </div>
                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div className="w-full lg:w-1/2 flex flex-col">
                    <Field
                      type="text"
                      name="fullName"
                      placeholder="Full Name"
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                      ${
                        touched.fullName && errors.fullName
                          ? "border border-danger"
                          : "border-none"
                      }`}
                      disabled={isSubmitting}
                    />
                    {touched.fullName && errors.fullName && (
                      <p className="text-danger text-sm mt-1">
                        {errors.fullName}
                      </p>
                    )}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-col">
                    <Field
                      type="text"
                      name="jobTitle"
                      placeholder="Job Title"
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                      ${
                        touched.jobTitle && errors.jobTitle
                          ? "border border-danger"
                          : "border-none"
                      }`}
                      disabled={isSubmitting}
                    />
                    {touched.jobTitle && errors.jobTitle && (
                      <p className="text-danger text-sm mt-1">
                        {errors.jobTitle}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div className="w-full lg:w-1/2 flex flex-col">
                    <Field
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                    ${
                      touched.email && errors.email
                        ? "border border-danger"
                        : "border-none"
                    }`}
                      disabled={isSubmitting}
                    />
                    {touched.email && errors.email && (
                      <p className="text-danger text-sm mt-1">{errors.email}</p>
                    )}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-col">
                    <div className="flex">
                      <Field
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    ${
                      touched.phoneNumber && errors.phoneNumber
                        ? "border border-danger"
                        : "border-none"
                    }`}
                        disabled={isSubmitting}
                      />
                    </div>
                    {touched.phoneNumber && errors.phoneNumber && (
                      <p className="text-danger text-sm mt-1">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-t-xl rounded-bl-xl p-4 lg:p-8">
                <div className="text-secondary text-md lg:text-lg font-extrabold uppercase mb-2">
                  Business Details
                </div>
                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div className="w-full lg:w-1/2 flex flex-col">
                    <Field
                      type="number"
                      name="annualTurnover"
                      placeholder="Annual Turnover"
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                      ${
                        touched.annualTurnover && errors.annualTurnover
                          ? "border border-danger"
                          : "border-none"
                      }`}
                      disabled={isSubmitting}
                    />
                    {touched.annualTurnover && errors.annualTurnover && (
                      <p className="text-danger text-sm mt-1">
                        {errors.annualTurnover}
                      </p>
                    )}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-col">
                    <Field
                      type="number"
                      name="expectedTurnover"
                      placeholder="Expected Turnover for GBT Products Per Year"
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                      ${
                        touched.expectedTurnover && errors.expectedTurnover
                          ? "border border-danger"
                          : "border-none"
                      }`}
                      disabled={isSubmitting}
                    />
                    {touched.expectedTurnover && errors.expectedTurnover && (
                      <p className="text-danger text-sm mt-1">
                        {errors.expectedTurnover}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div
                    className={`w-full lg:w-1/2 flex flex-col ${
                      values.industryFocus === "other"
                        ? "lg:w-1/2"
                        : "lg:w-full"
                    }`}
                  >
                    <Field
                      as="select"
                      name="industryFocus"
                      className={`bg-sky rounded-t-xl rounded-bl-xl text-base text-dark font-normal px-4 py-5 focus:outline-none 
                      ${
                        touched.industryFocus && errors.industryFocus
                          ? "border border-danger"
                          : "border-none"
                      }`}
                      disabled={isSubmitting}
                    >
                      <option className="text-dark/50 font-light" value="">
                        Select the industry focus
                      </option>
                      <option value="network">Network</option>
                      <option value="security">Security</option>
                      <option value="cloud">Cloud</option>
                      <option value="other">Other</option>
                    </Field>
                    {touched.industryFocus && errors.industryFocus && (
                      <p className="text-danger text-sm mt-1">
                        {errors.industryFocus}
                      </p>
                    )}
                  </div>

                  {values.industryFocus === "other" && (
                    <div className="w-full lg:w-1/2 flex flex-col">
                      <Field
                        type="text"
                        name="customIndustryFocus"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setFieldValue("customIndustryFocus", value);
                        }}
                        placeholder="Other Industry Focus"
                        className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                        ${
                          touched.customIndustryFocus &&
                          errors.customIndustryFocus
                            ? "border border-danger"
                            : "border-none"
                        }`}
                        disabled={isSubmitting}
                      />
                      {touched.customIndustryFocus &&
                        errors.customIndustryFocus && (
                          <p className="text-danger text-sm mt-1">
                            {errors.customIndustryFocus}
                          </p>
                        )}
                    </div>
                  )}
                </div>

                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div className="w-full flex flex-col">
                    <Select
                      name="preferredBrands"
                      isSearchable
                      isMulti
                      placeholder="Select a preferred Brands"
                      options={options}
                      onChange={(selectedOption: any) => {
                        const brands = selectedOption.map(
                          (option: any) => option.value
                        );

                        setFieldValue("preferredBrands", brands);
                      }}
                      className={`rounded-t-xl rounded-bl-xl focus:outline-none
                        ${
                          touched.preferredBrands && errors.preferredBrands
                            ? "border border-danger"
                            : "border-none"
                        }`}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "100%",
                          backgroundColor: "#EBF5FF",
                          borderRadius: "0.75rem 0.75rem 0 0.75rem",
                          fontSize: "1rem",
                          color: "#012448",
                          fontWeight: "400",
                          padding: "1rem 1rem",
                          border: "#000",
                          outline: "none",
                          boxShadow: "none",
                          zIndex: "auto",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "rgba(1, 36, 72, 0.5)",
                          fontWeight: "300",
                        }),

                        option: (baseStyle, state) => ({
                          ...baseStyle,
                          background: state.isSelected ? "#17B0CE" : "#FFF",
                          "&:hover": {
                            background: state.isSelected
                              ? "#17B0CE"
                              : "#DCEAF8",
                          },
                          textAlign: "left",
                        }),
                      }}
                    />

                    {touched.preferredBrands && errors.preferredBrands && (
                      <p className="text-danger text-sm mt-1">
                        {errors.preferredBrands}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-t-xl rounded-bl-xl p-4 lg:p-8">
                <div className="text-secondary text-md lg:text-lg font-extrabold uppercase mb-2">
                  Required Documents
                </div>
                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div className="w-full lg:w-1/2 flex flex-col">
                    <div className="w-full">
                      <label className="mb-2.5 block font-normal text-black dark:text-white">
                        {t("Trade License")}
                      </label>
                      <input
                        ref={tradeLicenseRef} // Attach ref to file input
                        type="file"
                        name="tradeLicense"
                        onChange={(event: any) => {
                          setTouched({
                            ...touched,
                            tradeLicense: true,
                          });

                          setFieldValue("tradeLicense", event.target.files[0]);
                        }}
                        placeholder="Trade License"
                        className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                    ${
                      touched.tradeLicense && errors.tradeLicense
                        ? "border border-danger"
                        : "border-none"
                    }`}
                        disabled={isSubmitting}
                      />
                    </div>
                    {touched.tradeLicense && errors.tradeLicense && (
                      <p className="text-danger text-sm mt-1">
                        {errors.tradeLicense}
                      </p>
                    )}
                  </div>

                  <div className="w-full lg:w-1/2 flex flex-col">
                    <div className="w-full">
                      <label className="mb-2.5 block font-normal text-black dark:text-white">
                        {t("Vat Certificate")}
                      </label>
                      <input
                        ref={vatCertificateRef} // Attach ref to file input
                        type="file"
                        name="vatCertificate"
                        onChange={(event: any) => {
                          setTouched({
                            ...touched,
                            vatCertificate: true,
                          });

                          setFieldValue(
                            "vatCertificate",
                            event.target.files[0]
                          );
                        }}
                        placeholder="Vat Certificate"
                        className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                        ${
                          touched.vatCertificate && errors.vatCertificate
                            ? "border border-danger"
                            : "border-none"
                        }`}
                        disabled={isSubmitting}
                      />
                    </div>
                    {touched.vatCertificate && errors.vatCertificate && (
                      <p className="text-danger text-sm mt-1">
                        {errors.vatCertificate}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-col lg:flex-row gap-6 mb-4">
                  <div className="w-full">
                    <label className="mb-2.5 block font-normal text-black dark:text-white">
                      {t("Other Files")}
                    </label>
                    <input
                      ref={filesRef} // Attach ref to file input
                      type="file"
                      name="files"
                      onChange={(event: any) => {
                        setTouched({
                          ...touched,
                          vatCertificate: true,
                        });

                        setFieldValue("files", event.target.files[0]);
                      }}
                      className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus:outline-none
                      ${
                        touched.files && errors.files
                          ? "border border-danger"
                          : "border-none"
                      }`}
                      disabled={isSubmitting}
                    />
                    {touched.files && errors.files && (
                      <p className="text-danger text-sm mt-1">{errors.files}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-t-xl rounded-bl-xl p-4 lg:p-8">
                <div className="text-secondary text-md lg:text-lg font-extrabold uppercase mb-2">
                  Agreement
                </div>
                <div className="w-full flex flex-row gap-2 mb-4">
                  <Field
                    type="checkbox"
                    name="agreeToTerms"
                    checked={isChecked}
                    onChange={(e: any) => setIsChecked(e.target.checked)}
                  />
                  <label htmlFor="agreeToTerms">
                    I agree to the{" "}
                    <a
                      href="/reseller-terms"
                      className="underline hover:no-underline text-primary"
                    >
                      terms and conditions
                    </a>
                  </label>
                </div>
              </div>

              <button
                type="submit"
                disabled={!isChecked}
                className={`w-full items-center justify-center rounded-t-xl rounded-bl-xl border border-none p-4 transition hover:bg-opacity-90 ${
                  isChecked
                    ? "bg-gradient-to-r from-dark to-secondary text-white"
                    : "bg-slate-300 text-white cursor-not-allowed"
                }`}
              >
                {isSubmitting ? (
                  <span className="flex justify-center gap-2">
                    {t("Appling...")} <ButtonLoader />
                  </span>
                ) : (
                  <span className="text-lightGray text-base font-bold uppercase">
                    {t("Apply Now")}
                  </span>
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default ResellerForm;
