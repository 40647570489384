import React from "react";
import Menu from "../components/Common/Menu";
import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import { terms, conclusion } from "../utils/resellerTerms";

function ResellerTerms() {
  return (
    <div>
      <div>
        <Menu />
      </div>

      <Header
        title="Reseller"
        subTitle="Terms and Conditions"
        miniTitle="GBT Pages"
        description="By registering as a reseller with Grand Block Technologies (GBT), you agree to the following terms and conditions. Please read them carefully before proceeding with your registration."
      />

      <div className="w-full bg-lightGray">
        <div className="flex justify-center items-center">
          <ul className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
            <div className="w-auto">
              {terms.map((item, index) => (
                <li className="my-5" key={index}>
                  <div className="flex flex-col gap-2">
                    <div className="text-dark font-semibold text-lg leading-5 hover:text-dark/50">
                      {index + 1}. {item.title}
                    </div>
                    <div className="w-full text-justify text-dark/50 font-normal text-base leading-5">
                      {item.description.split("\n").map((line, lineIndex) => (
                        <p key={lineIndex} className="mb-3">
                          {index + 1}.{lineIndex + 1}. {line}
                        </p>
                      ))}
                    </div>
                  </div>
                </li>
              ))}
              {conclusion.description}
            </div>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default ResellerTerms;
