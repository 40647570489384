import React from "react";
import Menu from "../components/Common/Menu";
import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import { terms } from "../utils/terms";

function Terms() {
  return (
    <div>
      <div>
        <Menu />
      </div>

      <Header
        title="GBT Solutions"
        subTitle="Terms of Service"
        miniTitle="GBT Pages"
        description="Welcome to GBT Solutions! We're excited to have you here and want to ensure your experience is both enjoyable and productive. These Terms of Service (Terms) outline the agreement between you (you or User) and GBT Solutions (GBT, we, us, or our) regarding your access to and use of our website, products, services, and applications (collectively, the Services)."
      />

      <div className="w-full bg-lightGray">
        <div className="flex justify-center items-center">
          <ul className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
            <div className="w-auto">
              {terms.map((item, index) => (
                <li className="my-5" key={index}>
                  <div className="flex flex-col gap-2">
                    <div className="text-dark font-semibold text-lg leading-5 hover:text-dark/50">
                      {index + 1}. {item.title}
                    </div>
                    <div className="w-full text-justify text-dark/50 font-normal text-base leading-5">
                      {item.description.split("\n").map((line, lineIndex) => (
                        <p key={lineIndex}>
                          {lineIndex > 0 && "- "}
                          {line
                            .split("contact us")
                            .map((part, partIndex, arr) => (
                              <React.Fragment key={partIndex}>
                                {part}
                                {partIndex < arr.length - 1 && (
                                  <a
                                    href="/contact"
                                    className="underline hover:no-underline text-primary"
                                  >
                                    contact us
                                  </a>
                                )}
                              </React.Fragment>
                            ))}
                        </p>
                      ))}
                    </div>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}
export default Terms;
