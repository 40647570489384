import Menu from "../components/Common/Menu";
import Header from "../components/Common/Header";
import CampaignForm from "../components/Contact/CampaignForm";
import Footer from "../components/Common/Footer";

function Campaign() {
  return (
    <div>
      <Menu />

      <Header
        title="Get in Touch"
        subTitle="We're Here to Help"
        miniTitle="GBT Help Center"
        description=" Welcome to the contact and help page. We are happy to offer you
        a variety of ways to help on this page, which includes
        information to contact us."
      />

      <CampaignForm />

      <Footer />
    </div>
  );
}
export default Campaign;
