import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Campaign } from "../../interfaces/Campaign";
import { Country } from "../../interfaces/Country";
import ButtonLoader from "../../Common/Loader/ButtonLoader";
import * as campaignServices from "../../services/campaign.services";
import Select from "react-select";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const CampaignForm: React.FC<any> = () => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countryList = data.map((country: any) => ({
          name: country.name.common,
          code: country.cca2,
          phoneCode:
            country.idd.root +
            (country.idd.suffixes ? country.idd.suffixes[0] : ""),
        }));
        setCountries(countryList);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const options = [
    { value: "SNR-CPE-ME2-Lite", label: "SNR-CPE-ME2-Lite" },
    { value: "SNR-RT420-F21-LTE", label: "SNR-RT420-F21-LTE" },
    { value: "SNR-RT622-G41", label: "SNR-RT622-G41" },
    { value: "SNR-MD2", label: "SNR-MD2" },
    { value: "SNR-CPE-W4N", label: "SNR-CPE-W4N" },
    { value: "SNR Series S2982G", label: "SNR Series S2982G" },
    { value: "SNR Series S2985G", label: "SNR Series S2985G" },
    { value: "SNR S2989G-24TX", label: "SNR S2989G-24TX" },
    { value: "SNR Series S2995G", label: "SNR Series S2995G" },
    { value: "SNR 300X-24FQ", label: "SNR 300X-24FQ" },
    { value: "SNR S5210X-8F", label: "SNR S5210X-8F" },
  ];

  const initialValues: Campaign = {
    fullName: "",
    country: "",
    countryCode: "",
    phoneNumber: "",
    product: "",
  };

  const FormSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(3, "First name must contain at least 3 letters")
      .required(t("required")),
    country: Yup.string().required(t("required")),
    phoneNumber: Yup.string()
      .matches(/^\+?\d+$/, "Phone number must contain only numbers")
      .min(9, "Phone number must contain at least 9 digits")
      .max(10, "Phone number must contain maximum 10 digits")
      .required(t("required")),
    product: Yup.string().required(t("required")),
  });

  const handleForm = async (values: Campaign) => {
    setSubmitting(true);
    campaignServices
      .create(values)
      .then((response: any) => {
        if (response.status === 200) {
          setSubmitting(false);
          toast.success(
            "Thank you! Your form has been submitted successfully.",
            {
              position: "top-right",
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-full bg-sky">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 pb-12 cursor-default">
        <div className="bg-gradient-to-r from-primary to-dark bg-clip-text text-transparent text-3xl lg:text-4xl font-extrabold uppercase drop-shadow-lightBlue">
          Contact Us Form
        </div>
        <p className="text-justify text-dark/50 text-lg font-normal leading-6 my-4">
          Ready for better tech? Let's get started! Contact us by fill out the
          form.
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={(values: Campaign, { resetForm }) => {
            values.phoneNumber = values.countryCode + values.phoneNumber;

            handleForm(values);
            resetForm();
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form className="bg-white rounded-t-xl rounded-bl-xl p-4 lg:p-8">
              <div className="flex flex-col lg:flex-row gap-6 mb-6">
                <div className="w-full flex flex-col">
                  <Field
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    ${
                      touched.fullName && errors.fullName
                        ? "border border-danger"
                        : "border-none"
                    }`}
                    disabled={isSubmitting}
                  />
                  {touched.fullName && errors.fullName && (
                    <p className="text-danger text-sm mt-1">
                      {errors.fullName}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-6 mb-6">
                <div className="w-full lg:w-1/2 flex flex-col">
                  <Field name="country">
                    {({ field }: { field: any }) => (
                      <Select
                        {...field}
                        isSearchable
                        value={
                          values.country
                            ? {
                                value: values.country,
                                label:
                                  countries.find(
                                    (c: any) => c.code === values.country
                                  )?.name || values.country,
                              }
                            : null
                        }
                        placeholder="Search and select a country"
                        options={countries.map((country) => ({
                          value: country.code,
                          label: country.name,
                        }))}
                        onChange={(selectedOption: any) => {
                          const selectedCountry = countries.find(
                            (country) => country.code === selectedOption.value
                          );

                          setFieldValue("country", selectedCountry?.name); // Set country value
                          setFieldValue(
                            "countryCode",
                            selectedCountry?.phoneCode || ""
                          ); // Update phone number field with phone code
                        }}
                        className={`rounded-t-xl rounded-bl-xl focus:outline-none
                        ${
                          touched.country && errors.country
                            ? "border border-danger"
                            : "border-none"
                        }`}
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            width: "100%",
                            backgroundColor: "#EBF5FF",
                            borderRadius: "0.75rem 0.75rem 0 0.75rem",
                            fontSize: "1rem",
                            color: "#012448",
                            fontWeight: "400",
                            padding: "16px 16px",
                            border: "#000",
                            outline: "none",
                            boxShadow: "none",
                            zIndex: "auto",
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: "rgba(1, 36, 72, 0.5)",
                            fontWeight: "300",
                          }),

                          option: (baseStyle, state) => ({
                            ...baseStyle,
                            background: state.isSelected ? "#17B0CE" : "#FFF",
                            "&:hover": {
                              background: state.isSelected
                                ? "#17B0CE"
                                : "#DCEAF8",
                            },
                            textAlign: "left",
                          }),
                        }}
                      />
                    )}
                  </Field>

                  {touched.country && errors.country && (
                    <p className="text-danger text-sm mt-1">{errors.country}</p>
                  )}
                </div>

                <div className="w-full lg:w-1/2 flex flex-col">
                  <div className="flex">
                    <Field
                      type="text"
                      name="countryCode"
                      placeholder="Code"
                      className={`w-1/6 bg-sky rounded-tl-xl rounded-bl-xl border-r border-gray text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    `}
                      disabled
                    />
                    <Field
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      className={`w-full bg-sky rounded-tr-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus:outline-none
                    ${
                      touched.phoneNumber && errors.phoneNumber
                        ? "border border-danger"
                        : "border-none"
                    }`}
                      disabled={isSubmitting}
                    />
                  </div>
                  {touched.phoneNumber && errors.phoneNumber && (
                    <p className="text-danger text-sm mt-1">
                      {errors.phoneNumber}
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <Select
                  name="product"
                  isSearchable
                  placeholder="Search and select a product"
                  options={options}
                  onChange={(selectedOption: any) => {
                    const selectedProduct = options.find(
                      (product) => product.value === selectedOption.value
                    );

                    setFieldValue("product", selectedProduct?.value); // Set product value
                  }}
                  className={`rounded-t-xl rounded-bl-xl focus:outline-none
                  ${
                    touched.product && errors.product
                      ? "border border-danger"
                      : "border-none"
                  }`}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "100%",
                      backgroundColor: "#EBF5FF",
                      borderRadius: "0.75rem 0.75rem 0 0.75rem",
                      fontSize: "1rem",
                      color: "#012448",
                      fontWeight: "400",
                      padding: "16px 20px",
                      border: "#000",
                      outline: "none",
                      boxShadow: "none",
                      zIndex: "auto",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "rgba(1, 36, 72, 0.5)",
                      fontWeight: "300",
                    }),

                    option: (baseStyle, state) => ({
                      ...baseStyle,
                      background: state.isSelected ? "#17B0CE" : "#FFF",
                      "&:hover": {
                        background: state.isSelected ? "#17B0CE" : "#DCEAF8",
                      },
                      textAlign: "left",
                    }),
                  }}
                />
              </div>

              <div className="flex justify-center items-center gap-8">
                <button
                  type="submit"
                  className="w-full sm:w-1/2 flex items-center justify-center rounded-t-xl rounded-bl-xl border border-none bg-primary p-4 text-white transition hover:bg-opacity-90"
                >
                  {isSubmitting ? (
                    <span className="flex justify-center gap-2">
                      {t("Submit...")} <ButtonLoader />
                    </span>
                  ) : (
                    <span className="text-lightGray text-base font-bold uppercase">
                      {t("Submit")}
                    </span>
                  )}
                </button>
                <Link
                  to="https://wa.me/+971542372522"
                  className="w-full sm:w-1/2 flex items-center justify-center rounded-t-xl rounded-bl-xl border border-none bg-[#25D366] p-4 text-white transition hover:bg-opacity-90"
                >
                  <div className="flex flex-row gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#FFFFFF"
                      viewBox="0 0 256 256"
                    >
                      <path d="M187.58,144.84l-32-16a8,8,0,0,0-8,.5l-14.69,9.8a40.55,40.55,0,0,1-16-16l9.8-14.69a8,8,0,0,0,.5-8l-16-32A8,8,0,0,0,104,64a40,40,0,0,0-40,40,88.1,88.1,0,0,0,88,88,40,40,0,0,0,40-40A8,8,0,0,0,187.58,144.84ZM152,176a72.08,72.08,0,0,1-72-72A24,24,0,0,1,99.29,80.46l11.48,23L101,118a8,8,0,0,0-.73,7.51,56.47,56.47,0,0,0,30.15,30.15A8,8,0,0,0,138,155l14.61-9.74,23,11.48A24,24,0,0,1,152,176ZM128,24A104,104,0,0,0,36.18,176.88L24.83,210.93a16,16,0,0,0,20.24,20.24l34.05-11.35A104,104,0,1,0,128,24Zm0,192a87.87,87.87,0,0,1-44.06-11.81,8,8,0,0,0-6.54-.67L40,216,52.47,178.6a8,8,0,0,0-.66-6.54A88,88,0,1,1,128,216Z"></path>
                    </svg>
                    {t("Contact Us Via WhatsApp")}
                  </div>
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default CampaignForm;
