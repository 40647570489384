import whatsapp from "../../images/whatsapp-logo.svg";

const WhatsAppIcon = () => {
  return (
    <div className="bg-[#25D366] fixed right-4 bottom-4 z-50 rounded-full p-2 ransition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
      <a
        href="https://wa.me/+971542372522"
        className="whatsapp-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsapp} alt="WhatsApp Icon" />
      </a>
    </div>
  );
};

export default WhatsAppIcon;
