import axios from "axios";
import * as base from "./base.services";

export const create = (reseller: any) => {
  let formData = new FormData();
  formData.append("companyName", reseller.companyName);
  formData.append("companyWebsite", reseller.companyWebsite);
  formData.append("businessType", reseller.businessType);
  formData.append("customBusinessType", reseller.customBusinessType);
  formData.append("businessAddress", reseller.businessAddress);
  formData.append("country", reseller.country);
  formData.append("fullName", reseller.fullName);
  formData.append("email", reseller.email);
  formData.append("phoneNumber", reseller.phoneNumber);
  formData.append("jobTitle", reseller.jobTitle);
  formData.append("annualTurnover", reseller.annualTurnover);
  formData.append("expectedTurnover", reseller.expectedTurnover);
  formData.append("industryFocus", reseller.industryFocus);
  formData.append("customIndustryFocus", reseller.customIndustryFocus);
  formData.append("preferredBrands", reseller.preferredBrands);
  formData.append("tradeLicense", reseller.tradeLicense);
  formData.append("vatCertificate", reseller.vatCertificate);
  formData.append("files", reseller.files);

  return axios.post(
    `${base.apiUrl}/client/reseller_registerations`,
    formData,
    {}
  );
};
