import Menu from "../components/Common/Menu";
import Header from "../components/Common/Header";
import ResellerForm from "../components/Contact/ResellerForm";
import Footer from "../components/Common/Footer";

function BecomeReseller() {
  return (
    <div>
      <Menu />

      <Header
        title="Become a Reseller"
        subTitle="with Grand Block Technologies"
        miniTitle="GBT Help Center"
        description="Partnering with us as a reseller gives you access to leading brands and high-quality products that meet market demands. Enjoy exclusive pricing and discounts to maximize your profitability while benefiting from comprehensive technical support and training to enhance your expertise. We provide dedicated marketing and sales resources to help you attract and retain customers, along with a dedicated account manager to ensure smooth operations and personalized assistance. Additionally, resellers gain early access to new products, priority order processing, and opportunities for joint marketing campaigns, ensuring a competitive edge in the industry."
      />

      <ResellerForm />

      <Footer />
    </div>
  );
}
export default BecomeReseller;
