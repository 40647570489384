import { Fragment, useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { Switch } from "@headlessui/react";
import gbt from "../../images/logo.png";
import { ReactComponent as CaretDown } from "../../images/icons/caret-down.svg";
import { ReactComponent as List } from "../../images/icons/list.svg";
import { ReactComponent as XIcon } from "../../images/icons/x.svg";

import { offers } from "../../utils/offers";
import WhatsAppIcon from "./WhatsAppIcon";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Menu: React.FC<any> = ({ className }: { className: string }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const location = useLocation();

  const [isSolutionsOpen, setSolutionsOpen] = useState(false);
  const [isServicesOpen, setServicesOpen] = useState(false);
  const [isProductsOpen, setProductsOpen] = useState(false);

  const isSolutionsPageActive = location.pathname.startsWith("/solutions");
  const isServicesPageActive = location.pathname.startsWith("/services");
  const isProductsPageActive = location.pathname.startsWith("/products");

  return (
    <header className={`bg-lightGray sticky top-0 z-40  ${className}`}>
      <WhatsAppIcon />
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between gap-24 px-4 py-6 xl:px-4"
        aria-label="Global"
      >
        <div className="flex">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Grand Block Technology</span>
            <img className="h-11 w-auto" src={gbt} alt="GBT-Logo" />
          </a>
        </div>

        <div className="lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <List className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <PopoverGroup className="hidden lg:flex lg:gap-x-8">
          <Link
            to="/"
            className={`text-sm xl:text-base leading-normal hover:text-primary ${
              location.pathname === "/"
                ? "text-primary font-bold underline decoration-2 underline-offset-8"
                : "text-black/75 font-normal"
            }`}
          >
            Home
          </Link>
          <div
            className="relative"
            onMouseEnter={() => setSolutionsOpen(true)}
            onMouseLeave={() => setSolutionsOpen(false)}
          >
            <Popover className="relative">
              <PopoverButton
                className={`flex items-center gap-x-1 text-sm xl:text-base leading-6 hover:text-primary focus:outline-none ${
                  isSolutionsPageActive
                    ? "text-primary font-bold underline decoration-2 underline-offset-8"
                    : "text-black/75 font-normal"
                }`}
              >
                Solutions
                <CaretDown
                  className="h-4 w-4 flex-none text-black/75"
                  aria-hidden="true"
                />
              </PopoverButton>

              <Transition
                as={Fragment}
                show={isSolutionsOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white ring-1 ring-primary">
                  <div className="p-4">
                    {offers.solutions.map((item, index) => (
                      <div
                        key={index}
                        className={`group relative flex gap-x-2 rounded-lg p-3 text-sm leading-6 hover:bg-sky ${
                          location.pathname === item.link
                            ? "bg-gray"
                            : "bg-gray-200"
                        }`}
                      >
                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <img
                            className="h-6 w-6"
                            src={item.icon}
                            alt="GBT-Solutions"
                          />
                        </div>

                        <div className="flex-auto">
                          <Link to={item.link} className="block font-semibold">
                            {item.title}
                            <span className="absolute inset-0" />
                          </Link>
                          <p className="mt-1 text-black/75">
                            {item.description.slice(0, 40)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </PopoverPanel>
              </Transition>
            </Popover>
          </div>

          <div
            className="relative"
            onMouseEnter={() => setServicesOpen(true)}
            onMouseLeave={() => setServicesOpen(false)}
          >
            <Popover className="relative">
              <PopoverButton
                className={`flex items-center gap-x-1 text-sm xl:text-base leading-6 hover:text-primary focus:outline-none ${
                  isServicesPageActive
                    ? "text-primary font-bold underline decoration-2 underline-offset-8"
                    : "text-black/75 font-normal"
                }`}
              >
                Services
                <CaretDown
                  className="h-4 w-4 flex-none text-black/75"
                  aria-hidden="true"
                />
              </PopoverButton>

              <Transition
                as={Fragment}
                show={isServicesOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white ring-1 ring-primary">
                  <div className="p-4">
                    {offers.services.map((item, index) => (
                      <div
                        key={index}
                        className={`group relative flex gap-x-2 rounded-lg p-3 text-sm leading-6 hover:bg-sky ${
                          location.pathname === item.link
                            ? "bg-gray"
                            : "bg-gray-200"
                        }`}
                      >
                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <img
                            className="h-6 w-6"
                            src={item.icon}
                            alt="GBT-Services"
                          />
                        </div>

                        <div className="flex-auto">
                          <Link
                            to={item.link}
                            className="block font-semibold text-black/75"
                          >
                            {item.title}
                            <span className="absolute inset-0" />
                          </Link>
                          <p className="mt-1 text-black/75">
                            {item.description.slice(0, 40)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </PopoverPanel>
              </Transition>
            </Popover>
          </div>

          <div
            className="relative"
            onMouseEnter={() => setProductsOpen(true)}
            onMouseLeave={() => setProductsOpen(false)}
          >
            <Popover className="relative">
              <PopoverButton
                className={`flex items-center gap-x-1 text-sm xl:text-base leading-6 hover:text-primary focus:outline-none ${
                  isProductsPageActive
                    ? "text-primary font-bold underline decoration-2 underline-offset-8"
                    : "text-black/75 font-normal"
                }`}
              >
                Products
                <CaretDown
                  className="h-4 w-4 flex-none text-black/75"
                  aria-hidden="true"
                />
              </PopoverButton>

              <Transition
                as={Fragment}
                show={isProductsOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white ring-1 ring-primary">
                  <div className="p-4">
                    {offers.products.map((item, index) => (
                      <div
                        key={index}
                        className={`group relative flex gap-x-2 rounded-lg p-3 text-sm leading-6 hover:bg-sky ${
                          location.pathname === item.link
                            ? "bg-gray"
                            : "bg-gray-200"
                        }`}
                      >
                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <img
                            className="h-6 w-6"
                            src={item.icon}
                            alt="GBT-Products"
                          />
                        </div>

                        <div className="mt-1 flex-auto">
                          <Link
                            to={item.link}
                            className="block font-semibold text-black/75"
                          >
                            {item.title}
                            <span className="absolute inset-0" />
                          </Link>
                          <p className="mt-1 text-black/75">
                            {item.description.slice(0, 40)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </PopoverPanel>
              </Transition>
            </Popover>
          </div>

          <Link
            to="/training"
            className={`text-sm xl:text-base leading-normal hover:text-primary ${
              location.pathname === "/training"
                ? "text-primary font-bold underline decoration-2 underline-offset-8"
                : "text-black/75 font-normal"
            }`}
          >
            Training & Hiring
          </Link>

          <Link
            to="/about"
            className={`text-sm xl:text-base leading-normal hover:text-primary ${
              location.pathname === "/about"
                ? "text-primary font-bold underline decoration-2 underline-offset-8"
                : "text-black/75 font-normal"
            }`}
          >
            About
          </Link>

          <Link
            to="/become-reseller"
            className={`text-sm xl:text-base leading-normal hover:text-primary ${
              location.pathname === "/become-reseller"
                ? "text-primary font-bold underline decoration-2 underline-offset-8"
                : "text-black/75 font-normal"
            }`}
          >
            Become a Reseller
          </Link>

          <Link
            to="/contact"
            className={`text-sm xl:text-base leading-normal hover:text-primary ${
              location.pathname === "/contact"
                ? "text-primary font-bold underline decoration-2 underline-offset-8"
                : "text-black/75 font-normal"
            }`}
          >
            Contact Us
          </Link>
        </PopoverGroup>

        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
              enabled ? "bg-gray-300" : "bg-gray-100",
              "relative inline-flex h-7 w-12 flex-shrink-0 cursor-pointer rounded-t-md rounded-bl-md border-2 border-secondary/75 transition-colors duration-200 ease-in-out"
            )}
          >
            <span className="sr-only">Language</span>
            <span
              className={classNames(
                enabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none relative inline-block h-6 w-6 transform rounded-tl-sm rounded-bl-sm bg-gradient-to-r from-primary to-secondary shadow ring-0 transition duration-200 ease-in-out"
              )}
            >
              <span
                className={classNames(
                  enabled
                    ? "opacity-0 duration-100 ease-out"
                    : "opacity-100 duration-200 ease-in",
                  "absolute inset-0 text-white flex h-full w-full items-center justify-center transition-opacity"
                )}
                aria-hidden="true"
              >
                E
              </span>
              <span
                className={classNames(
                  enabled
                    ? "opacity-100 duration-200 ease-in"
                    : "opacity-0 duration-100 ease-out",
                  "absolute inset-0 text-white flex h-full w-full items-center justify-center transition-opacity"
                )}
                aria-hidden="true"
              >
                ع
              </span>
            </span>
          </Switch>
        </div> */}
      </nav>

      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-black/75">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">GBT</span>
              <img className="h-12 w-auto" src={gbt} alt="" />
            </Link>

            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-dark"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="flow-root">
            <div className="my-2 divide-y divide-gray">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base leading-7 hover:bg-gray ${
                    location.pathname === "/"
                      ? "text-primary font-bold underline decoration-2 underline-offset-8"
                      : "text-black/75 font-semibold"
                  }`}
                >
                  Home
                </Link>

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton
                        className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base leading-7 hover:bg-gray ${
                          isSolutionsPageActive
                            ? "text-primary font-bold underline decoration-2 underline-offset-8"
                            : "text-black/75 font-semibold"
                        }`}
                      >
                        Solutions
                        <CaretDown
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </DisclosureButton>

                      <DisclosurePanel className="mt-2 space-y-2">
                        {offers.solutions.map((item, index) => (
                          <DisclosureButton
                            key={index}
                            as="a"
                            href={item.link}
                            className={`block rounded-lg py-2 pl-8 pr-3 text-sm font-semibold leading-7 text-black/75 ${
                              location.pathname === item.link
                                ? "bg-gray"
                                : "bg-gray-200"
                            }`}
                          >
                            <div className="flex gap-2">
                              <img
                                className="h-6 w-6"
                                src={item.icon}
                                alt="GBT-Solutions"
                              />
                              {item.title}
                            </div>
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton
                        className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base leading-7 hover:bg-gray ${
                          isServicesPageActive
                            ? "text-primary font-bold underline decoration-2 underline-offset-8"
                            : "text-black/75 font-semibold"
                        }`}
                      >
                        Services
                        <CaretDown
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {offers.services.map((item, index) => (
                          <DisclosureButton
                            key={index}
                            as="a"
                            href={item.link}
                            className={`block rounded-lg py-2 pl-8 pr-3 text-sm font-semibold leading-7 text-black/75${
                              location.pathname === item.link
                                ? "bg-gray"
                                : "bg-gray-200"
                            }`}
                          >
                            <div className="flex gap-2">
                              <img
                                className="h-6 w-6"
                                src={item.icon}
                                alt="GBT-Solutions"
                              />
                              {item.title}
                            </div>
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <DisclosureButton
                        className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base leading-7 hover:bg-gray ${
                          isProductsPageActive
                            ? "text-primary font-bold underline decoration-2 underline-offset-8"
                            : "text-black/75 font-semibold"
                        }`}
                      >
                        Products
                        <CaretDown
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                        />
                      </DisclosureButton>
                      <DisclosurePanel className="mt-2 space-y-2">
                        {offers.products.map((item, index) => (
                          <DisclosureButton
                            key={index}
                            as="a"
                            href={item.link}
                            className={`block rounded-lg py-2 pl-8 pr-3 text-sm font-semibold leading-7 text-black/75${
                              location.pathname === item.link
                                ? "bg-gray"
                                : "bg-gray-200"
                            }`}
                          >
                            <div className="flex gap-2">
                              <img
                                className="h-6 w-6"
                                src={item.icon}
                                alt="GBT-Solutions"
                              />
                              {item.title}
                            </div>
                          </DisclosureButton>
                        ))}
                      </DisclosurePanel>
                    </>
                  )}
                </Disclosure>

                <Link
                  to="/training"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base leading-7 hover:bg-gray ${
                    location.pathname === "/training"
                      ? "text-primary font-bold underline decoration-2 underline-offset-8"
                      : "text-black/75 font-semibold"
                  }`}
                >
                  Training & Hiring
                </Link>

                <Link
                  to="/about"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base leading-7 hover:bg-gray ${
                    location.pathname === "/about"
                      ? "text-primary font-bold underline decoration-2 underline-offset-8"
                      : "text-black/75 font-semibold"
                  }`}
                >
                  About
                </Link>
                <Link
                  to="/become-reseller"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base leading-7 hover:bg-gray ${
                    location.pathname === "/become-reseller"
                      ? "text-primary font-bold underline decoration-2 underline-offset-8"
                      : "text-black/75 font-semibold"
                  }`}
                >
                  Become a Reseller
                </Link>

                <Link
                  to="/contact"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base leading-7 hover:bg-gray ${
                    location.pathname === "/contact"
                      ? "text-primary font-bold underline decoration-2 underline-offset-8"
                      : "text-black/75 font-semibold"
                  }`}
                >
                  Contact & Help
                </Link>
              </div>

              {/* <div className="py-6">
                <Switch
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? "bg-gray-300" : "bg-gray-100",
                    "relative inline-flex h-7 w-12 flex-shrink-0 cursor-pointer rounded-t-md rounded-bl-md border-2 border-secondary/75 transition-colors duration-200 ease-in-out"
                  )}
                >
                  <span className="sr-only">Language</span>
                  <span
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none relative inline-block h-6 w-6 transform rounded-tl-sm rounded-bl-sm bg-gradient-to-r from-primary to-secondary shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  >
                    <span
                      className={classNames(
                        enabled
                          ? "opacity-0 duration-100 ease-out"
                          : "opacity-100 duration-200 ease-in",
                        "absolute inset-0 text-white flex h-full w-full items-center justify-center transition-opacity"
                      )}
                      aria-hidden="true"
                    >
                      E
                    </span>
                    <span
                      className={classNames(
                        enabled
                          ? "opacity-100 duration-200 ease-in"
                          : "opacity-0 duration-100 ease-out",
                        "absolute inset-0 text-white flex h-full w-full items-center justify-center transition-opacity"
                      )}
                      aria-hidden="true"
                    >
                      ع
                    </span>
                  </span>
                </Switch>
              </div> */}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
};
export default Menu;
